import React from 'react';

import { Accordion } from 'react-accessible-accordion';

import AccordionElement from 'components/AccordionElement/AccordionElement';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './AccordionElementContainer.scss';

const AccordionElements = ({ elements }) => {
  if (elements) {
    return elements.map((element, i) => (
      <AccordionElement key={i} data={element} />
    ));
  }

  return null;
};

const AccordionElementContainer = ({ data }) => (
  <div className="accordion-element-container">
    <ContentBox>
      <Accordion allowZeroExpanded={true}>
        <AccordionElements elements={data.accordionElements} />
      </Accordion>
    </ContentBox>
  </div>
);

export default AccordionElementContainer;
