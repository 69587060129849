import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import AfterHeroSection from 'components/AfterHeroSection/AfterHeroSection';
import AccordionElementContainer from 'components/AccordionElementContainer/AccordionElementContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const ProductsServicesPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.productsServicesYaml.title} />
      <Hero data={data.productsServicesYaml.hero} />
      <AfterHeroSection data={data.productsServicesYaml.afterHeroSection} />
      <AccordionElementContainer
        data={data.productsServicesYaml.accordionElementContainer}
      />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default ProductsServicesPage;

export const pageQuery = graphql`
  query ProductsServicesPageQuery {
    productsServicesYaml {
      id
      title

      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      afterHeroSection {
        title
        subtitle
        description
        callToActionButton {
          text
          url
        }
      }

      accordionElementContainer {
        accordionElements {
          title
          subtitle
          description
          ticks {
            title
            description
          }
        }
      }
    }
  }
`;
